// src/components/sections/OurServices.js

import React from 'react';
import '../style/usluge.scss';

const OurServices = () => {
    return (
        <div id="usluge" className="usluge-section">
            <div className="usluge-text">
                <h1>Naše Usluge</h1>
                <p>Mi smo mala porodična firma, osnovana 2000. godine. Naš prvobitni fokus bio je na izradi i izvozu bukovih elemenata.</p>
                <p>Tokom vremena, usavršavali smo se i razvijali, a danas smo ponosni proizvođači dečijih krevetića, komoda i ostalog nameštaja za decu.</p>
                <p>Naš razvoj i posvećenost</p>
                <p>Kroz godine smo stalno ulagali u naše veštine, fokusirajući se na pružanje visokokvalitetnog nameštaja za najmlađe.</p>
            </div>
            <div className="usluge-image">
                <img src="/assets/image2.jpg" alt="About Us" />
            </div>
        </div>
    );
};

export default OurServices;