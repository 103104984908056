// src/components/landing/section/Hero.js
import React from 'react';
import '../style/hero.scss';

const Hero = () => {
    return (
        <section id="pocetna" className="hero">
                <div className="hero-content">
                    <h1>
                        STIL<br />
                        KOMFORT &<br />
                        PRISTUPAČNO
                    </h1>
                    <a href="#ponuda" className="hero-button">Ponuda</a>
                </div>
                <div className="hero-image">
                    <img src="/assets/image1.png" alt="Style and Comfort" />
                </div>
        </section>
    );
};

export default Hero;
