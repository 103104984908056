// src/components/navbar/Navbar.js
import React, { useState } from 'react';
import '../style/navbar.scss';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate

    const handleScroll = (e, id) => {
        e.preventDefault();
        document.getElementById(id).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
        setIsOpen(false); // Close the menu after a link is clicked
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLogoClick = (e) => {
        e.preventDefault();
        navigate('/login'); // Navigate to /login
    };

    return (
        <nav className="navbar">
            <a href="/login" className="logo" onClick={handleLogoClick}>
                Gorcic Still
            </a>
            <div className="hamburger" onClick={toggleMenu}>
                {isOpen ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={`menu ${isOpen ? 'open' : ''}`}>
                <li><a href="#pocetna" onClick={(e) => handleScroll(e, 'pocetna')}>Pocetna</a></li>
                <li><a href="#usluge" onClick={(e) => handleScroll(e, 'usluge')}>Usluge</a></li>
                <li><a href="#onama" onClick={(e) => handleScroll(e, 'onama')}>O Nama</a></li>
                <li><a href="#ponuda" onClick={(e) => handleScroll(e, 'ponuda')}>Ponuda</a></li>
                <li><a href="#galerija" onClick={(e) => handleScroll(e, 'galerija')}>Galerija</a></li>
                <li><a href="#kontakt" onClick={(e) => handleScroll(e, 'kontakt')}>Kontakt</a></li>
            </ul>
        </nav>
    );
};

export default Navbar;