// src/components/sections/Store.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../style/store.scss'; // Import the corresponding SCSS file

const Store = () => {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        // Fetch products from the backend
        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://api.decjikrevetici.com/products');
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const handleOrder = (productId) => {
        navigate('/order', { state: { productId } });
    };

    return (
        <div id="ponuda" className="store">
            <h1>Naše Ponude</h1>
            <div className="product-grid">
                {products.map(product => (
                    <div key={product.id} className="product-card">
                        <h2>{product.name}</h2>
                        <img src={product.image} alt={product.name} />
                        <p>{product.description}</p>
                        <p>Cena: {product.price} RSD</p>
                        <p>Veličina: {product.size}</p>
                        <button className="order-button" onClick={() => handleOrder(product.id)}>Poruci</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Store;