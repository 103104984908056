// src/components/landing/Landingpage.js
import React from 'react';
import Navbar from './main/Navbar';
import Footer from './main/Footer';
import Hero from './section/Hero';
import Usluge from './section/Usluge';
import About from './section/About';
import Store from './section/Store';
import Gallery from './section/Gallery';

const Landingpage = () => {
    return (
        <div>
            <Navbar />
            <Hero />
            <Usluge />
            <About />
            <Store />
            <Gallery />
            <Footer />
        </div>
    );
};

export default Landingpage;
