// src/components/landing/main/Footer.js

import React from 'react';
import '../style/footer.scss';
import { FaPhoneAlt, FaEnvelope, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer id="kontakt" className="footer">
      <div className="footer-top">
        <div className="contact-info">
          <FaPhoneAlt className="icon" />
          <span>+381 64 00 03 783</span>
        </div>
        <div className="contact-info">
          <FaEnvelope className="icon" />
          <span>imagogorcic@gmail.com</span>
        </div>
      </div>
      <div className="footer-social">
        <a href="https://instagram.com/krevetici_decji_2023" target="_blank" rel="noopener noreferrer">
          <FaInstagram className="icon" />
        </a>
      </div>
        <div className="footer-info">
            <p>SZR Gorcic Stil | PIB: 114068261 | MB: 67300424</p>
            <p>© 2024 dreamstrl.com All rights reserved.</p>
        </div>
    </footer>
  );
};

export default Footer;
