// src/components/sections/AboutUs.js

import React from 'react';
import '../style/about.scss';

const AboutUs = () => {
    return (
        <div id="onama" className="about-section">
            <div className="about-text">
                <h1>O Nama</h1>
                <p>Sa preko 20 godina rada, naš tim je uspešno rešio bezbroj naizgled nerešivih zahteva, prilagođavajući se specifičnim potrebama svakog klijenta.</p>
                <p>Naš stručan tim je obučen kroz dugogodišnju praksu i posvećenost projektima, čime garantujemo vrhunski kvalitet u svakom koraku izrade.</p>
                <p>Posebnu pažnju posvećujemo preciznoj obradi bukovog drveta, osiguravajući dugotrajnost i pouzdanost svakog proizvoda. Prilikom farbanja koristimo boje na vodenoj bazi, potpuno prilagođene kontaktu sa bebama, čime obezbeđujemo da naši proizvodi budu potpuno sigurni i bez štetnih efekata po zdravlje.</p>
            </div>
            <div className="about-image">
                <img src="/assets/image3.jpg" alt="O Nama" />
            </div>
        </div>
    );
};

export default AboutUs;