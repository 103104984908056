// src/components/dashboard/Dashboard.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './dashboard.scss';

const Dashboard = () => {
    const [section, setSection] = useState('');
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);
    const [newProduct, setNewProduct] = useState({
        name: '',
        image: '',
        description: '',
        price: '',
        size: ''
    });
    const [editingProduct, setEditingProduct] = useState(null);

    useEffect(() => {
        if (section === 'products') {
            fetchProducts();
        } else if (section === 'orders') {
            fetchOrders();
        }
    }, [section]);

    const fetchProducts = async () => {
        try {
            const response = await axios.get('https://api.decjikrevetici.com/products');
            setProducts(response.data);
        } catch (error) {
            console.error("There was an error fetching the products!", error);
        }
    };

    const fetchOrders = async () => {
        try {
            const response = await axios.get('https://api.decjikrevetici.com/orders');
            setOrders(response.data);
        } catch (error) {
            console.error("There was an error fetching the orders!", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewProduct({ ...newProduct, [name]: value });
    };

    const handleAddProduct = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://api.decjikrevetici.com/add-product', newProduct);
            fetchProducts();
            setNewProduct({ name: '', image: '', description: '', price: '', size: '' });
        } catch (error) {
            console.error("There was an error adding the product!", error);
        }
    };

    const handleEditProduct = (product) => {
        setEditingProduct(product);
        setNewProduct(product);
    };

    const handleUpdateProduct = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://api.decjikrevetici.com/edit-product/${editingProduct.id}`, newProduct);
            fetchProducts();
            setNewProduct({ name: '', image: '', description: '', price: '', size: '' });
            setEditingProduct(null);
        } catch (error) {
            console.error("There was an error updating the product!", error);
        }
    };

    const handleDeleteProduct = async (id) => {
        try {
            await axios.delete(`https://api.decjikrevetici.com/delete-product/${id}`);
            fetchProducts();
        } catch (error) {
            console.error("There was an error deleting the product!", error);
        }
    };

    return (
        <div className="dashboard-container">
            <h1>Welcome to the Dashboard</h1>

            <div className="dashboard-buttons">
                <button onClick={() => setSection('orders')}>Porudžbine</button>
                <button onClick={() => setSection('products')}>Proizvodi</button>
            </div>

            <div className="dashboard-content">
                {section === 'orders' && <Orders orders={orders} />}
                {section === 'products' && (
                    <div>
                        <AddProductForm
                            newProduct={newProduct}
                            handleInputChange={handleInputChange}
                            handleAddProduct={handleAddProduct}
                            handleUpdateProduct={handleUpdateProduct}
                            editingProduct={editingProduct}
                        />
                        <Products
                            products={products}
                            handleEditProduct={handleEditProduct}
                            handleDeleteProduct={handleDeleteProduct}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const Orders = ({ orders }) => (
    <div>
        <h2>Orders Section</h2>
        <div className="order-list">
            {orders.map(order => (
                <div key={order.id} className="order-card">
                    <p>ID: {order.id}</p>
                    <p>First Name: {order.firstName}</p>
                    <p>Last Name: {order.lastName}</p>
                    <p>Country: {order.country}</p>
                    <p>City: {order.city}</p>
                    <p>Address: {order.address}</p>
                    <p>Email: {order.email}</p>
                    <p>Phone Number: {order.phoneNumber}</p>
                    <p>Product ID: {order.productId}</p>
                    <p>Quantity: {order.quantity}</p>
                </div>
            ))}
        </div>
    </div>
);

const Products = ({ products, handleEditProduct, handleDeleteProduct }) => (
    <div>
        <h2>Products Section</h2>
        <div className="product-grid">
            {products.map(product => (
                <div key={product.id} className="product-card">
                    <h2>{product.name}</h2>
                    <img src={product.image} alt={product.name} />
                    <p>{product.description}</p>
                    <p>Price: {product.price} RSD</p>
                    <p>Size: {product.size}</p>
                    <button onClick={() => handleEditProduct(product)}>Edit</button>
                    <button onClick={() => handleDeleteProduct(product.id)}>Delete</button>
                </div>
            ))}
        </div>
    </div>
);

const AddProductForm = ({ newProduct, handleInputChange, handleAddProduct, handleUpdateProduct, editingProduct }) => (
    <form onSubmit={editingProduct ? handleUpdateProduct : handleAddProduct}>
        <h2>{editingProduct ? "Edit Product" : "Add New Product"}</h2>
        <div className="form-group">
            <label htmlFor="name">Product Name:</label>
            <input
                type="text"
                id="name"
                name="name"
                value={newProduct.name}
                onChange={handleInputChange}
                required
            />
        </div>
        <div className="form-group">
            <label htmlFor="image">Product Image URL:</label>
            <input
                type="text"
                id="image"
                name="image"
                value={newProduct.image}
                onChange={handleInputChange}
                required
            />
        </div>
        <div className="form-group">
            <label htmlFor="description">Product Description:</label>
            <textarea
                id="description"
                name="description"
                value={newProduct.description}
                onChange={handleInputChange}
                required
            />
        </div>
        <div className="form-group">
            <label htmlFor="price">Price:</label>
            <input
                type="text"
                id="price"
                name="price"
                value={newProduct.price}
                onChange={handleInputChange}
                required
            />
        </div>
        <div className="form-group">
            <label htmlFor="size">Size:</label>
            <input
                type="text"
                id="size"
                name="size"
                value={newProduct.size}
                onChange={handleInputChange}
                required
            />
        </div>
        <button type="submit">{editingProduct ? "Update Product" : "Add Product"}</button>
    </form>
);

export default Dashboard;