// src/components/sections/Gallery.js
import React from 'react';
import '../style/gallery.scss';

const Gallery = () => {
    return (
        <div id="galerija" className="gallery">
            <h1>Galerija</h1>
            <div className="gallery-grid">
                <div className="gallery-item">
                    <img src="/assets/image2.jpg" alt="Gallery Image 1" />
                </div>
                <div className="gallery-item">
                    <img src="/assets/image3.jpg" alt="Gallery Image 2" />
                </div>
                <div className="gallery-item">
                    <img src="/assets/image2.jpg" alt="Gallery Image 3" />
                </div>
                <div className="gallery-item">
                    <img src="/assets/image3.jpg" alt="Gallery Image 4" />
                </div>
                <div className="gallery-item">
                    <img src="/assets/image2.jpg" alt="Gallery Image 5" />
                </div>
                <div className="gallery-item">
                    <img src="/assets/image3.jpg" alt="Gallery Image 6" />
                </div>
                <div className="gallery-item">
                    <img src="/assets/image2.jpg" alt="Gallery Image 7" />
                </div>
                <div className="gallery-item">
                    <img src="/assets/image3.jpg" alt="Gallery Image 8" />
                </div>
            </div>
        </div>
    );
};

export default Gallery;
