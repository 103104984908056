// src/components/order/Order.js

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './order.scss';

const Order = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        country: '',
        city: '',
        address: '',
        email: '',
        phoneNumber: '',
        productName: '',
        quantity: ''
    });

    const [products, setProducts] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch products when component mounts
        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://api.decjikrevetici.com/products');
                setProducts(response.data);
            } catch (error) {
                console.error("There was an error fetching the products!", error);
            }
        };
        fetchProducts();
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCancel = () => {
        setFormData({
            firstName: '',
            lastName: '',
            country: '',
            city: '',
            address: '',
            email: '',
            phoneNumber: '',
            productName: '',
            quantity: ''
        });
        setError('');
        setSuccess('');
    };

    const handleSubmitOrder = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.decjikrevetici.com/add-order', formData);
            setSuccess('Uspešno ste poručili, sačekajte da vas neko kontaktira.');
        } catch (error) {
            console.error('There was an error placing the order!', error);
            setError('Došlo je do greške, pokušajte kasnije.');
        }
    };

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                navigate('/');
            }, 3000); // Redirect after 3 seconds
        }
    }, [success, navigate]);

    return (
        <div className="order-container">
            <h1>Place an Order</h1>
            <form onSubmit={handleSubmitOrder}>
                <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="First Name" required />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Last Name" required />
                </div>
                <div className="form-group">
                    <label htmlFor="country">Country</label>
                    <input type="text" name="country" value={formData.country} onChange={handleChange} placeholder="Country" required />
                </div>
                <div className="form-group">
                    <label htmlFor="city">City</label>
                    <input type="text" name="city" value={formData.city} onChange={handleChange} placeholder="City" required />
                </div>
                <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Address" required />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" required />
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} placeholder="Phone Number" required />
                </div>
                <div className="form-group">
                    <label htmlFor="productName">Product</label>
                    <select name="productName" value={formData.productName} onChange={handleChange} required>
                        <option value="" disabled>Select Product</option>
                        {products.map(product => (
                            <option key={product.id} value={product.name}>{product.name}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="quantity">Quantity</label>
                    <input type="number" name="quantity" value={formData.quantity} onChange={handleChange} placeholder="Quantity" required />
                </div>
                <button type="submit">Place Order</button>
                <button type="button" onClick={handleCancel}>Cancel</button>
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>}
            </form>
        </div>
    );
};

export default Order;